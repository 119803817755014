import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import cn from 'classnames';
import TableCell from 'components/TableCell';
import Icon from 'components/Icon';

import styles from './styles';

const NoData = ({ classes, type, colSpan, title, className }) => {
  if (type === 'table') {
    return (
      <TableRow className={cn({ [className]: !!className })}>
        <TableCell data-node="no_data_text" align="center" colSpan={colSpan}>
          {title}
        </TableCell>
      </TableRow>
    );
  }

  if (type === 'chart') {
    return (
      <div data-node="no_data_chart" className={classes.chartRoot}>
        <div className={classes.chartIcon}>
          <Icon icon="chartBarSolid" />
        </div>
        <div className={classes.chartTitle}>{title}</div>
      </div>
    );
  }

  return (
    <div className={cn(classes.root, className)} data-node="no_data">
      {title}
    </div>
  );
};

NoData.propTypes = {
  classes: PropTypes.shape().isRequired,
  type: PropTypes.oneOf(['div', 'table', 'chart']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  colSpan: PropTypes.number,
  className: PropTypes.string,
};

NoData.defaultProps = {
  type: 'div',
  title: 'No data',
  colSpan: 1,
  className: '',
};

export default withStyles(styles)(NoData);
