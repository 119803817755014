export default theme => ({
  root: {
    textAlign: 'center',
    padding: 28,
    width: '100%',
    color: theme.palette.text.disabled,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(18),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 2,
  },

  chartRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  chartIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.border,
  },
});
